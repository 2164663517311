import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider,
  Button
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import SettingsNotification from 'src/components/SettingsNotification';
import InternetConnection from 'src/components/InternetConnection';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import { InstitutionProvider } from '../src/contexts/InstitutionContext';
import { ImportCompanySetupProvider } from 'src/contexts/ImportForCompanyContext';
import { SetupProvider } from '../src/contexts/SetupContext';
import { UserProvider } from '../src/contexts/UserContext';
import { ApplicationProvider } from '../src/contexts/ApplicationContext';
import { AdminProvider } from '../src/contexts/AdminContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import {client} from './utils/GQLClient';
import { useSnackbar } from 'notistack';
import { DashboardProvider } from 'src/contexts/DashboardContext';
import { FeeSetupProvider } from 'src/contexts/FeeSetupContext';
import { YearProvider } from 'src/contexts/YearContext';
import { TermProvider } from 'src/contexts/TermContext';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();
const App = () => {
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });
  const refresh = () => {
    window.location.reload(false)
  }

  window.addEventListener('offline', function(e) {
    enqueueSnackbar('Internet not Connected', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
        autoHideDuration : null
    },
    autoHideDuration : null,
      variant: 'error',
      action: <Button onClick = {refresh}>Refresh</Button>
    }); });
    window.addEventListener('online', function(e) { 
    });
 

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            dense={false}
            maxSnack={3}
          >
            <Router history={history} >
          
              <AuthProvider >
              <DashboardProvider>
            
              <ImportCompanySetupProvider>
              <FeeSetupProvider>
              <InstitutionProvider>
                <SetupProvider>
                  <UserProvider>
                  <TermProvider>
                        <YearProvider>
                    <ApplicationProvider>
                      <AdminProvider>
                <GlobalStyles />
                <ScrollReset />
                <GoogleAnalytics />
                                                {/* here cookie and settings notification */}
                <InternetConnection />
                {renderRoutes(routes)}
                </AdminProvider>
                </ApplicationProvider>
                </YearProvider>
                      </TermProvider>
                </UserProvider>
                </SetupProvider>
                </InstitutionProvider>
                </FeeSetupProvider>
                </ImportCompanySetupProvider>
                </DashboardProvider>
              </AuthProvider>
              
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
