import React, { createContext, useReducer } from 'react';
import gql from 'graphql-tag';
import {client} from '../utils/GQLClient';

const initialCreateState = {
  isCreated: false,
  isInitialised: false,
  user: null,
  users: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isCreated, user } = action.payload;

      return {
        ...state,
        isCreated,
        isInitialised: true,
        user
      };
    }

    case 'UPDATE_INSTITUTION': {
      const { user ,updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        user
      };
    }
    case 'UPDATE_INSTITUTION_ERR': {
      const { err } = action.payload;

      return {
        ...state,
        updatesuccessfull: false,
        err
      };
    }

    default: {
      return { ...state };
    }
  }
};

// const getinstitutionquery = gql`
// query get_institution {
//   get_institution {
//     _id
//       user_id
//       institution_name
//       first_name
//       last_name 
//       owner_email
//       owner_contact
//       address {
//         street
//           city
//           state
//           postal_code
//           country
//       }
//       contact{
//         phone
//           mobile 
//           fax
//           website
//           secondary_email
//       }
//   }
// }
// `;

const UserContext = createContext({
  ...initialCreateState,
  add_user: () => Promise.resolve(),
  assign_user : () => Promise.resolve(),
  user_status_update : () => Promise.resolve(),
  user_edit : () => Promise.resolve(),

});

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCreateState);

  const add_user = async (       
    first_name   , 
    last_name    , 
    user_email   , 
    user_contact , 
    password     ,   
    sms ,  
email   ,  ) => {

    const  notifyuser = {
        sms : sms,
        email:email
    }

    try {
      const ADD = gql`
        mutation add_user(
          $first_name                  : String!
          $last_name                 : String!
          $user_email                  : String!
          $user_contact            : String!
          $password                : String!
          $notify              : notify
    
        ) {
            add_user(
                first_name    : $first_name    ,
                last_name     : $last_name     ,
                user_email    : $user_email    ,
                user_contact  : $user_contact  ,
                password      : $password      ,
                notify         :$notify        ,
          ) {
            _id
            first_name   
            last_name    
            user_email   
            user_contact 
  
            notify       {
                sms
                email
            }
          }
        }
       
      `;
      const { data } = await client('adminapi')
        .mutate({
          mutation: ADD,
          
          variables: {
            first_name  : first_name  ,
            last_name   : last_name   ,
            user_email  : user_email  ,
            user_contact: user_contact,
            password    : password    ,
            notify    : notifyuser    ,
          },
     
        })
        .then(res => {
       
          return res;
        });



      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: data.add_user,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      dispatch({
        type: 'UPDATE_INSTITUTION_ERR',
        payload: {
          err: err
        }
      });

    }
  };

  
  const assign_user = async (       
    id  ,   
app_name,
app_role , ) => {


    try {
      const ADD = gql`
        mutation assign_user(
       
          $_id      : String!
          $app_name : String!
          $app_role : String!
    
        ) {
          assign_user(
                _id        : $_id         ,
                app_name   : $app_name    ,
                app_role   : $app_role    ,
        
          ) {
            _id
            first_name   
            last_name    
            user_email   
            user_contact 
  
            user_apps{
              app_name
              app_pool
              app_role
              app_status
            }
          }
        }
       
      `;
      const { data } = await client('adminapi')
        .mutate({
          mutation: ADD,
          
          variables: {
            _id         :     id ,
            app_name: app_name,
            app_role: app_role
          },
          
     
         
       
     
        })
        .then(res => {
  
          return res;
        });


      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: data.assign_user,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      dispatch({
        type: 'UPDATE_INSTITUTION_ERR',
        payload: {
          err: err
        }
      });
    
    }
  };
  
  const user_status_update = async (       
    id  , status  
 ) => {


    try {
      const ADD = gql`
        mutation user_status_update(
       
          $_id      : String!
          $status : String!

    
        ) {
          user_status_update(
                _id        : $_id         ,
                status   : $status    ,

        
          ) {
            _id
            first_name   
            last_name    
            user_email   
            user_contact 
            status
          }
        }
       
      `;
      const { data } = await client('adminapi')
        .mutate({
          mutation: ADD,
          
          variables: {
            _id         :     id ,
            status: status,
        
          },
          
     
         
       
     
        })
        .then(res => {

          return res;
        });

      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: data.user_status_update,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      dispatch({
        type: 'UPDATE_INSTITUTION_ERR',
        payload: {
          err: err
        }
      });

    }
  };

  const user_edit = async (       
    id  , first_name,last_name  
 ) => {


    try {
      const ADD = gql`
        mutation update_user(
       
          $_id      : String!
          $first_name : String!
          $last_name : String!
    
        ) {
          update_user(
                _id        : $_id         ,
                first_name : $first_name,
                last_name :  $last_name

        
          ) {
            _id
            first_name   
            last_name    
            user_email   
            user_contact 
            status
          }
        }
       
      `;
      const { data } = await client('adminapi')
        .mutate({
          mutation: ADD,
          
          variables: {
            _id         :     id ,
            first_name: first_name,
            last_name : last_name
        
          },
          
     
         
       
     
        })
        .then(res => {

          return res;
        });


      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: data.user_status_update,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      dispatch({
        type: 'UPDATE_INSTITUTION_ERR',
        payload: {
          err: err
        }
      });

    }
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        add_user,
        assign_user,
        user_status_update,
        user_edit
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;