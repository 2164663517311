import React, { createContext, useReducer } from 'react';
import gql from 'graphql-tag';
import {client} from '../utils/GQLClient';
import getinstitutionquery from '../views/InstitutionNew/getinstitutionquery'

const initialCreateState = {
  isCreated: false,
  isInitialised: false,
  user: null,
  users: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isCreated, user } = action.payload;

      return {
        ...state,
        isCreated,
        isInitialised: true,
        user
      };
    }

    case 'UPDATE_INSTITUTION': {
      const { user ,updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        user
      };
    }
    case 'UPDATE_INSTITUTION_ERR': {
      const { err } = action.payload;

      return {
        ...state,
        updatesuccessfull: false,
        err
      };
    }

    default: {
      return { ...state };
    }
  }
};



const InstitutionContext = createContext({
  ...initialCreateState,
  update_institutuion: () => Promise.resolve(),

});

export const InstitutionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCreateState);

  const update_institutuion = async (phone ,         
    mobile   ,      
    fax  ,          
    website    ,    
    street  ,       
    city ,          
    state      ,    
    postal_code ,   
    country        ) => {

    try {
      const ADD = gql`
        mutation update_institution(
          $phone                   : String!
          $mobile                : String!
          $fax                     : String!
          $website             : String!
          $street              : String!
          $city              : String!
          $state               : String!
          $postal_code     : Int!
          $country         : String!
        ) {
            update_institution(
            phone                : $phone          
            mobile               : $mobile         
            fax                  : $fax            
            website              : $website        
            street               : $street         
            city                 : $city           
            state                : $state          
            postal_code          : $postal_code    
            country              : $country        
          ) {
            _id
            user_id
            institution_name
            first_name
            last_name 
            owner_email
            owner_contact
            address {
              street
                city
                state
                postal_code
                country
            }
            contact{
              phone
                mobile 
                fax
                website
                secondary_email
            }
          }
        }
       
      `;
      const { data } = await client('adminapi')
        .mutate({
          mutation: ADD,
          
          variables: {
            phone                :phone          ,
            mobile               :mobile         ,
            fax                  :fax            ,
            website              :website        ,
            street               :street         ,
            city                 :city           ,
            state                :state          ,
            postal_code          :parseInt(postal_code)    ,
            country              :country        ,
          },
          
     
            refetchQueries: [
              {
                query: getinstitutionquery,
           
              }
            ]
       
     
        })
        .then(res => {

          return res;
        });



      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: data.update_institution.contact,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      dispatch({
        type: 'UPDATE_INSTITUTION_ERR',
        payload: {
          err: err
        }
      });

    }
  };

  

  

  return (
    <InstitutionContext.Provider
      value={{
        ...state,
        update_institutuion,
      }}
    >
      {children}
    </InstitutionContext.Provider>
  );
};

export default InstitutionContext;