import React, { createContext, useReducer } from 'react';
import gql from 'graphql-tag';
import client from '../utils/GQLClient';
import axios from 'axios';

const initialCreateState = {
  isCreated: false,
  isInitialised: false,
  user: null,
  users: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isCreated, user } = action.payload;

      return {
        ...state,
        isCreated,
        isInitialised: true,
        user
      };
    }

    case 'UPDATE_INSTITUTION': {
      const { user, updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        user
      };
    }
    case 'UPDATE_INSTITUTION_ERR': {
      const { err, updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull: false,
        err
      };
    }
    case 'ADD_IMPORT': {
      const { user1, updatesuccessfull1 } = action.payload;

      return {
        ...state,
        updatesuccessfull1,
        user1
      };
    }
    case 'ADD_IMPORT_ERROR': {
      const { err1, updatesuccessfull1 } = action.payload;

      return {
        ...state,
        updatesuccessfull1: false,
        err1
      };
    }
    default: {
      return { ...state };
    }
  }
};

const ImportForCompanySetupContext = createContext({
  ...initialCreateState,
  importEmployees:()=>Promise.resolve(),
  importStudents:()=>Promise.resolve(),
  importFaculties:()=>Promise.resolve(),
});

export const ImportCompanySetupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCreateState);

  const importEmployees = async data => {

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/import_employee',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/import_employee',
          data
        );
      }

      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {

      if (err.response) {
    

        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }

    }
  };

  const importStudents = async data => {

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_import_students',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_import_students',
          data
        );
      }

      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {

      if (err.response) {
 

        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }

    }
  };

  const importFaculties = async data => {

    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/import_faculty',
          data
        );
      } else {
        response = await axios.post(
          'https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/import_faculty',
          data
        );
      }

      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user: response.data,
          updatesuccessfull: true
        }
      });
    } catch (err) {

      if (err.response) {

        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull: false
          }
        });
      }

    }
  };


  return (
    <ImportForCompanySetupContext.Provider
      value={{
        ...state,
        importEmployees,
        importStudents,
        importFaculties
      }}
    >
      {children}
    </ImportForCompanySetupContext.Provider>
  );
};

export default ImportForCompanySetupContext;
