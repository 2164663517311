import { LoadingButton } from '@mui/lab';
import React from 'react';
import Rules from 'src/Rules.json';

  const CustomLoadingButton = ({ isLoading, children, ...props }) => {
    return (
      <LoadingButton
        variant="contained"
        type="submit"
        fullWidth
        size="large"
        loading={isLoading}
        sx={{
            bgcolor: `${Rules.login_page.button_color}`,
            '&:hover': {
              bgcolor: `${Rules.login_page.button_color}`,
              color: theme =>
                theme.palette.mode === 'light'
                  ? 'common.white'
                  : 'grey.800'
            }
          }}
        {...props}
      >
        {children}
      </LoadingButton>
    );
  };
  
  export default CustomLoadingButton;