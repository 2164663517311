  
import gql from 'graphql-tag';

const getinstitutionquery = gql`
query get_institution {
  get_institution {
    _id
      user_id
      institution_name
      first_name
      last_name 
      owner_email
      owner_contact
      address {
        street
          city
          state
          postal_code
          country
      }
      contact{
        phone
          mobile 
          fax
          website
          secondary_email
      }
  }
}
`;

export default getinstitutionquery;