import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AuthGuard from 'src/components/Guards/AuthGuard';
import GuestGuard from 'src/components/Guards/GuestGuard';
import HomeView from 'src/views/home/HomeView';
import LoginView from 'src/views/auth/LoginView';
import LoadingScreen from 'src/components/Loader';
import SettingsLayout from 'src/views/Test';
import OfficeGuard from 'src/components/Guards/OfficeGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/accessdenied',
    component: lazy(() => import('src/views/errors/AccessDenied'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
   
    path: '/reset_password',
    component: lazy(() => import('src/views/auth/LoginView/Reset_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    component: lazy(() => import('src/views/auth/LoginView/Forgot_Password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirm',
    component: lazy(() => import('src/views/auth/LoginView/Confirm_forgot_password'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/confirmSignUpp',
    component: lazy(() =>
      import('src/views/auth/LoginView/confirmSignUpafterLogin')
    )
  },
  {
    path: '/group',
    guard: AuthGuard,
    routes: [ {
      exact: true,
      path: '/group/user_groups',

      component: lazy(() => import('src/views/home/HomeView'))
    },
  ]
},
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [ 
      // {
      //   exact: true,
      //   path: '/app/admin/groups',
  
      //   component: lazy(() => import('src/views/home/HomeView'))
      // },
      {
      exact: true,
      path: '/app/admin/home',

      component: lazy(() => import('src/views/Dashboard'))
    },
      {
        exact: true,
        path: '/app/admin/institutionNew',
        guard : OfficeGuard,
        component: lazy(() => import('src/views/InstitutionNew'))
      },
      {
        exact: true,
        path: '/app/admission/:page_id',
        guard : OfficeGuard,
        component: lazy(() => import('src/views/Admissions/Schedules'))
      },
      {
        exact: true,
        path: '/app/admission/:page_id/:schedule_id',
        guard : OfficeGuard,
        component: lazy(() => import('src/views/Admissions/Schedules/AddSchedules'))
      },
      {
        exact: true,
        path: '/app/add_admission/:page_id',
        guard : OfficeGuard,
        component: lazy(() => import('src/views/Admissions/Schedules/AddSchedules'))
      },
      {
        exact: true,
        path: '/app/admission_sched_detail/:page_id/:schedule_id',
        guard : OfficeGuard,
        component: lazy(() => import('src/views/Admissions/Schedules'))
      },
      {
        exact: true,
        path: '/app/payroll/approvals',
        component: lazy(() => import('src/views/HRMS/Approvals'))
      },
      {
        layout : SettingsLayout,
        exact: true,
        path: '/app/setting/usermanagement/applicationsNew',
        component: lazy(() => import('src/views/ApplicationsNew'))
      },
     
      {
        exact: true,
        path: '/app/admin/usersNew',
        component: lazy(() => import('src/views/UsersNew'))
      },
      {
        exact: true,
        path: '/app/admin/students/:admission_status/:id/:layout_id',
        component: lazy(() => import('src/views/StudentFee'))
      },
      {
        exact: true,
        path: '/app/admin/students/:admission_status/:student_id/orders/:order_id',
        component: lazy(() => import('src/views/StudentFee/Payments/StudentsFee/OrderDetailPage'))
      },
      {
        exact: true,
        path: '/app/fee/schedule/:id/:status',
        component: lazy(() => import('src/views/Schedule/DraftScheduleFee'))
      },
      {
        exact: true,
        path: '/app/fee/schedule/:id/active',
        component: lazy(() => import('src/views/Schedule/ActiveFeeSchedule'))
      },
      {
        exact: true,
        path: '/app/onlineExam/:mode',

        component: lazy(() => import('src/views/OnlineExamSchedule'))
      },
      {
        exact: true,
        path: '/app/onlineExam/:exam_id/seating/:tt_id/:session_id/session/:seating_id/room',

        component: lazy(() => import('src/views/OnlineExamRoom'))
      },
      {
        exact: true,
        path: '/app/onlineExam/:exam_id/seating',

        component: lazy(() => import('src/views/OnlineExamSeating'))
      },
      {
        exact: true,
        path: '/app/onlineExam/:exam_id/seating/:tt_id/:session_id/session',

        component: lazy(() => import('src/views/OnlineExamSession'))
      },
      {
        exact: true,
        path: '/app/onlineExam/:exam_id/seating/:tt_id/:session_id/session/bulkUpload',

        component: lazy(() => import('src/views/OnlineExamSession'))
      },
      {
        exact: true,
        path: '/app/admin/Students/:admission_status',
        component: lazy(() => import('src/views/StudentsList'))
      },
      {
        exact: true,
        path: '/app/admin/tansactions',
        component: lazy(() => import('src/views/TransactionsNew'))
      },
      {
        exact: true,
        path: '/app/exam/schedule',

        component: lazy(() => import('src/views/Schedule'))
      },
      {
        exact: true,
        path: '/app/admission/approvals',
        component: lazy(() => import('src/views/Approvals'))
      },
      {
        exact: true,
        path: '/app/admin/employees',
        component: lazy(() => import('src/views/Employees'))
      },
      {
        exact: true,
        path: '/app/admin/faculty',
        component: lazy(() => import('src/views/Faculty'))
      },
      {
        exact: true,
        path: '/app/payroll/attendance',

        component: lazy(() => import('src/views/HRMS/Attendance/List'))
      },
      {
        exact: true,
        path: '/app/payroll/leaveApplications',
        component: lazy(() => import('src/views/HRMS/LeaveApplications'))
      },
      {
        exact: true,
        path: '/app/payroll/movementRegister',
        component: lazy(() => import('src/views/HRMS/MovementRegister'))
      },
      {
        exact: true,
        path: '/app/admin/enabledsoon/:page_type',
        component: lazy(() => import('src/views/EnableSoon'))
      },
      {
        layout : SettingsLayout,
        exact: true,
        path: '/app/setting/usermanagement/enabledsoon/:page_type',
        component: lazy(() => import('src/views/EnableSoon'))
      },
      {
        layout : SettingsLayout,
        exact: true,
        path: '/app/setting/usermanagement/adminsNew',
        component: lazy(() => import('src/views/UsersNew'))
      },
      {
        exact: true,
        path: '/app/admin/setup',
        component: lazy(() => import('src/views/Setup'))
      },
      {
        exact: true,
        path: '/app/admin/classSetup',
        component: lazy(() => import('src/views/AcademicSetup/Layout'))
      },
      {
        exact: true,
        path: '/app/admin/add/:type/:schedule_id',
        component: lazy(() => import('src/views/Employees/AddEmployeeDetails'))
      },
      {
        exact: true,
        path: '/app/admin/add/:type',
        component: lazy(() => import('src/views/Employees/AddEmployeeDetails'))
      },
      {
        exact: true,
        path: '/app/admin/:type/edit/:id/:layout_id',
        component: lazy(() => import('src/views/Employees/AddEmployeeDetails'))
      },
      {
        exact: true,
        path: '/app/admin/employee/:id/:layout_id',
        component: lazy(() => import('src/views/CandidateDetails'))
      },
      {
        exact: true,
        path: '/app/admin/faculty/:id/:layout_id',
        component: lazy(() => import('src/views/Faculty/CandidateDetails'))
      },
      {
        exact: true,
        path: '/app/admin/notifications',
        component: lazy(() => import('src/views/Notifications'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/programs',
        component: lazy(() => import('src/views/InstSetup/Programs'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/quota',
        component: lazy(() => import('src/views/InstSetup/Quota'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/stream',
        component: lazy(() => import('src/views/InstSetup/Stream'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/stream/addStream',
        component: lazy(() => import('src/views/InstSetup/Stream/AddStream'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/stream/:id',
        component: lazy(() => import('src/views/InstSetup/Stream/Details'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/stream/:id/edit',
        component: lazy(() => import('src/views/InstSetup/Stream/StreamEditView'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/batch',
        component: lazy(() => import('src/views/InstSetup/Batch'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/batch/addBatch',
        component: lazy(() => import('src/views/InstSetup/Batch/AddBatch'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/batch/:id',
        component: lazy(() => import('src/views/InstSetup/Batch/BatchDetailView'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/batch/:id/edit',
        component: lazy(() => import('src/views/InstSetup/Batch/BatchEditView'))
      },
      {
        exact: true,
        layout: SettingsLayout,
        path: '/app/admin/settings/academicyear',
        component: lazy(() => import('src/views/InstSetup/AcademicYear'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/admin/templates/settings/EmailTemplate',
        component: lazy(() => import('src/views/InstSetup/EmailTemplate'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/GeneralSettings/Company',
        component: lazy(() => import('src/views/InstitutionNew'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/Usermanagement/Users/UsersDetails',
        component: lazy(() => import('src/views/InstSetup/Users'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/Integration/IntegrationDetails',
        component: lazy(() => import('src/views/InstSetup/Integrations/Integration'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/GeneralSettings/Personal',
        component: lazy(() => import('src/views/PersonalSetup'))
      },
      {
        layout: SettingsLayout,
        exact: true,
        path: '/app/Setting/Integration/IntegrationDetails',
        component: lazy(() => import('src/views/Integration'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  // {
  //   path: '/app',
  //   guard: AuthGuard,
  //   layout: AllApps,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/app/admin/applications/detail',

  //       component: lazy(() => import('src/views/Applications/AppDetails'))
  //     },
  //   ]
  // },

  {
    path: '*',
    // layout: MainLayout,
    routes: [
      {
        exact: true,
        guard: GuestGuard,
        path: '/',
        component: lazy(() => import('src/views/auth/LoginView/JWTLogin'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
