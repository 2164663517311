import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const OfficeGuard = ({ children }) => {
  const { user } = useAuth();
    if(user.user_group !== 'owner' ){
        return <Redirect to="/accessdenied" />;
      }
  return <>{children}</>;
};

OfficeGuard.propTypes = {
  children: PropTypes.node
};

export default OfficeGuard;
