  
import gql from 'graphql-tag';

const getregistrationquery = gql`
query get_setup_by_id ( 
 
  $institution_id : String!
  ){
  get_setup_by_id (  institution_id: $institution_id) {
    
     
    kyc {
      pan
      registration_no
      registration_copy
      aicte_no
      college_code
    }
    
  }
}
`;

export default getregistrationquery;