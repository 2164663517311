import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  Link,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { styled } from '@material-ui/styles';
import Page from 'src/components/Page';
import { Link as RouterLink } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Stack, IconButton } from '@mui/material';
import { Alert } from '@mui/lab';
import Rules from 'src/Rules.json';
import RoundedBox from 'src/components/Box/RoundedBox';
import stylesUseStyles from 'src/utils/styles/styles';
import CustomLoadingButton from 'src/components/Button/LoadingButton';

const InputTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    paddingLeft: 0
  }
}));

const useStyles = makeStyles(theme => ({
  paperroot: {
    width: '60%',
    minHeight: '100vh',
    // height: 600,
    // maxWidth: 600,
    //backgroundImage: Rules.login_page.leftImage,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px'
  },
  paperroot2: {
    height: 600,
    width: 60,
    backgroundImage: Rules.login_page.rightImage,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px'
  },
  cardContent: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
    minHeight: 400
  },
  rightScreen: {
    backgroundColor: Rules.login_page.textColor,
    minHeight: '100vh',
    minWidth : '40%'
    //maxWidth: 440,
    //borderRadius: '8px'
  },
  rightScreenMobile: {
    backgroundColor: Rules.login_page.textColor,
    height: 600,
    maxWidth: 340,
    borderRadius: '8px',
    marginRight: 5
  }
}));

let style = { color: Rules.login_page.detail_text_color };
const JWTLogin = ({ className, ...rest }) => {
  const classes = useStyles();
  const stylesClasses = stylesUseStyles();
  const { login } = useAuth();
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .max(50, 'Email must be less than 50 characters')
          .matches(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            'Please enter the valid Email address'
          )
          .required('Please enter the valid Email address'),
        password: Yup.string()
          .max(20, 'Password must be less than 20 characters')
          .required('Password is required')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await login(values.email, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (err.response.data.message === 'UserNotConfirmed') {
            history.push('/confirmSignUpp');
          }
          if (err.response) {
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.response.data.message });
              setSubmitting(false);
            }
          } else {
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: 'Network Error' });
              setSubmitting(false);
            }
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Page className={stylesClasses.loginRoot} title="Login">
            {!mobileDevice && (
              <Box
                className={classes.paperroot}
                display="flex"
                flexDirection="column"
                //alignItems="center"
                justifyContent="space-around"
                color={Rules.login_page.textColor}
                p= {15}
              >
                <Box>
                <Typography variant="h2" style={{ fontSize : 30, fontFamily : 'Roboto' }}>Roversity Admin</Typography>
                </Box>
                <Box>
                <Typography variant="body1" style={{ fontSize : 36, fontFamily : 'Roboto' }} gutterBottom >A powerful, yet easy-to-use application for managing your Institution.</Typography>
                <Typography style={{ fontSize : 22, fontFamily : 'Roboto' }} >
                A powerful, yet easy-to-use application for managing Institution.
                </Typography>
                </Box>
                <Box>
                <Typography variant="caption" style={{ fontSize : 14, fontFamily : 'Roboto' }}>
                © 2024 Rove Labs Pvt Ltd. All Rights Reserved.
                </Typography>
                </Box>
              </Box>
            )}

            <Box
              className={
                mobileDevice ? classes.rightScreenMobile : classes.rightScreen
              }
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box px={5}>
                <Box width={400}>
                  <Stack spacing={1}>
                    <Typography variant="h4">Sign in to Rove Admin </Typography>

                    {/* <Stack direction="row" spacing={0.5}>
                      <Typography variant="body2">New user?</Typography>

                      <Link
                        component={RouterLink}
                        to="/signUp"
                        variant="subtitle2"
                        style={style}
                      >
                        Create an account
                      </Link>
                    </Stack> */}
                  </Stack>
                  <Box mt={3} />
                  <Stack spacing={3}>
                    {errors.submit && (
                      <Alert severity="error">{errors.submit}</Alert>
                    )}

                    <Box display="flex" flexDirection="column" mb={-2}>
                      <Box mb={-1.5}>
                        <Typography variant="subtitle2">
                          Email Address
                        </Typography>
                      </Box>
                      <InputTextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        size="small"
                        helperText={touched.email && errors.email}
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        required
                        value={values.email}
                        variant="outlined"
                      />
                    </Box>

                    <Box display="flex" flexDirection="column">
                      <Box mb={-1.5}>
                        <Typography variant="subtitle2">Password</Typography>
                      </Box>
                      <InputTextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        margin="normal"
                        name="password"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        autoComplete="new-password"
                        variant="outlined"
                      
                      />
                    </Box>
                  </Stack>

                  <Stack alignItems="flex-end" sx={{ mb: 2, mt: 1 }}>
                    <Link
                      component={RouterLink}
                      to="/forgot"
                      variant="body2"
                      style={style}
                      underline="always"
                    >
                      Forgot password?
                    </Link>
                  </Stack>

                  <CustomLoadingButton isLoading={isSubmitting} type="submit">
                    LOGIN
                  </CustomLoadingButton>
                </Box>
              </Box>
            </Box>
            {/* {!mobileDevice && <Box className={classes.paperroot2} />} */}
          </Page>
        </form>
      )}
    </Formik>
  );
};

JWTLogin.propTypes = {
  className: PropTypes.string
};

export default JWTLogin;
