import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';
import Cookies from 'js-cookie';
import axios from 'axios';
import * as serviceWorker from 'src/serviceWorker';
import { ApolloProvider } from '@apollo/react-hooks';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import {client} from './utils/GQLClient';
import App from 'src/App';
import store from 'src/store';
import AWS from 'aws-sdk';
import { SnackbarProvider } from 'notistack';
require('dotenv').config();

// Initialize the Amazon Cognito credentials provider
AWS.config.region = 'ap-south-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'ap-south-1:bd4b499b-1b63-4351-968c-95a882ce6133',
});
AWS.config.update({
  accessKeyId: "AKIA5FVNOR7ZBLG552PQ",
  secretAccessKey: "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",
  region: "ap-south-1"
  
});


const axiosauthrefresh = require('axios-auth-refresh');


enableES5();

let accessToken = '';
let idToken = '';
const refreshAuthLogic = async (failedRequest) => {
  
  const rfsrt = Cookies.get('rfsrt');
  
  const options = {
    headers:  {rfsrt} ,
    withCredentials: true,
    skipAuthRefresh: true
  }
  const payload = {
    rfsrt : rfsrt,
    app_name: "general"
  }
 
  if(process.env.REACT_APP_ENV === 'test'){
    return await axios
    .post('https://auth.rovelabs.com/test/tokenRefresh',payload,{options})
    .then((tokenRefreshResponse) => {
        
        accessToken = tokenRefreshResponse.data.data.AccessToken;
        idToken = tokenRefreshResponse.data.data.IdToken;
        failedRequest.response.config.headers.Authorization = `accessToken ${idToken}`;
        Cookies.set('accessToken', accessToken);
        Cookies.set('idToken', idToken);
        return Promise.resolve();
    });
  } else {
    return await axios
    .post('https://auth.rovelabs.com/prod/tokenRefresh',payload,{options})
    .then((tokenRefreshResponse) => {
      accessToken = tokenRefreshResponse.data.data.AccessToken;
      idToken = tokenRefreshResponse.data.data.IdToken;
        failedRequest.response.config.headers.Authorization = `Bearer ${accessToken}`;
        Cookies.set('accessToken', accessToken);
        Cookies.set('idToken', idToken);
        return Promise.resolve();
    });
  }
}
  axios.interceptors.request.use((request) => {
 
  const accessToken = Cookies.get('accessToken'); 
  const idToken = Cookies.get('idToken');
  const refreshToken = Cookies.get('rfsrt') 
  const user_token = Cookies.get('user_token') 

  
  if(accessToken){
    request.headers = {'Authorization': `Bearer ${accessToken}`,
                      'IdToken': idToken,
                      'RefreshToken':refreshToken,
                      'userToken' : user_token
                    }
    //localStorageService.setToken(token)
  }
  return request;
})

axiosauthrefresh.default(axios, refreshAuthLogic, { pauseInstanceWhileRefreshing: true });

ReactDOM.render(
  <ApolloProvider client={client}> 
      <Provider store={store}>
    <SettingsProvider>
    <SnackbarProvider
            dense={false}
            maxSnack={3}
          >
      <App client={client}/>
      </SnackbarProvider>
    </SettingsProvider>
  </Provider>
  </ApolloProvider>
  ,
  document.getElementById('root')
);

serviceWorker.register();