import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Link,
  CardMedia,
  Typography,
  Avatar ,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

import useAuth from 'src/hooks/useAuth';

import JWTLogin from './JWTLogin';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent : 'center'
  },
  banner: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  bannerChip: {
    marginRight: theme.spacing(2)
  },
  methodIcon: {
    height: 30,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  cardContainer: {
    paddingBottom: 20,
    paddingTop: 40,
  },
  cardContent: {
    // padding: theme.spacing(2),
    display: 'flex',
    //flexDirection: 'column',
    minHeight: 400
  },
  cont : {
    padding: theme.spacing(2),
  },
  media : {
    minHeight : 400,
    width : 400,
    paddingLeft : 5,


  },
  currentMethodIcon: {
    height: 100,
      width: 100,
     
    avatar: {
      height: 100,
      width: 100
    }
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const { method } = useAuth();

  return (
    <Page
      className={classes.root}
      title="Login"
    >
     
    
       
  <JWTLogin />
          
    </Page>
  );
};

export default LoginView;
