/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import Logo from 'src/components/Logo';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import Contacts from './Contacts';
import AWS from 'aws-sdk';
import {

  Box,
  Divider,
  Drawer,
  Chip,
  Hidden,
  AppBar,
  Toolbar,
  IconButton,
  Link,
  List,
  ListSubheader,
  Typography,
  Avatar,
  makeStyles
} from '@material-ui/core';
import Account from './Account';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Search from './Search';
import Settings from './Settings';
import {
  Edit as EditIcon,
  BarChart as BarChartIcon,
  PieChart as PieChartIcon
} from 'react-feather';
import NavItem from './NavItem';
import Label from 'src/components/Label';
import Notifications from './Notifications';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import {
  useDashboardContext,
  DashboardProvider
} from 'src/contexts/DashboardContext';
import Rules from 'src/Rules.json'
import Year from './Year';
import Term from './Term';

const Users = {
  items: [
    {
      title: 'Dashboard',
      href : '/app/admin/home',
      icon: 'material-symbols:dashboard-outline'
    },
    // {
    //   title: 'Institution',
    //   href : '/app/admin/institutionNew',
    //   icon: 'tdesign:institution'
     
    // },
    // {
    //   title: 'User Groups',
    //   href : '/app/admin/applicationsNew',
    //   icon: 'tdesign:menu-application'
     
    // },
    // {
    //   title: 'Users',
    //   href : '/app/admin/adminsNew',
    //   icon: 'mdi:users'

     
    // },

    {
      title: 'People',
      href: '/app/admin/employees',
      icon: `fluent:people-community-20-regular`,
      items: [
      {
        title: 'Employees',
        href : '/app/admin/employees',
        icon: 'clarity:employee-group-line'
       
      },
      {
        title: 'Faculty',
        href : '/app/admin/faculty',
        icon: 'mdi:teacher'
       
      },
    {
      title: 'Students',
      href : '/app/admin/Students/live',
      icon: 'ph:student'
    },
  ]
},
    {
      title: 'Admissions',
      href: '/app/admission/approvals',
      icon: `material-symbols-light:other-admission-outline`,
      items: [
        {
          title: 'Tracker',
          href: '/app/admission/admission_schedules',
          icon: 'carbon:event-schedule'
        },
        {
          title: 'Certificates',
          href: '/app/admission/approvals',
          icon: 'fluent:certificate-16-regular'
        },
        {
          title: 'ID Card',
          href: '/app/admin/enabledsoon/IDCard',
          icon: 'mingcute:idcard-line'
        },
      ]
    },
    {
      title: 'Fee',
      href: '/app/admin/tansactions',
      icon: `healthicons:money-bag-outline`,
      items: [
        // {
        //   title: 'Output Students',
        //   href: '/app/admin/students/completed',
        //   icon: 'pepicons-pencil:people'
        // },
        {
          title: 'Tracker',
          href: '/app/exam/schedule',
          icon: 'carbon:event-schedule'
        },
        {
          title: 'Transactions',
          href: '/app/admin/tansactions',
          icon: 'carbon:cics-transaction-server-zos'
        },
        {
          title: 'Configuration',
          href: '/app/admin/enabledsoon/Configuration',
          icon: 'grommet-icons:configure'
        }
        
      ]
    },
    {
      title: 'Exam Seating',
      href: '/app/onlineExam',
      icon: 'material-symbols-light:event-seat-outline-rounded',
      items: [
        {
          title: 'Online Exam',
          href: '/app/onlineExam/online',
          icon: 'material-symbols-light:online-prediction-rounded'
        },
        // {
        //   title: 'Online Test',
        //   href: '/app/exam/onlineExam',
        //   icon: 'arcticons:readera'
        // },
        {
          title: 'Offline Exam',
          href: '/app/onlineExam/offline',
          icon: 'ph:exam-light'
        }
      ]
    },
    {
      title: 'HR Tracker',
      href: '/app/payroll',
      icon: 'la:id-card-solid',
      items: [
        {
          title: 'Attendance',
          href: '/app/payroll/attendance',
          icon: 'ic:outline-assignment-ind'

          // icon: AssignmentTurnedInOutlinedIcon
        },

        {
          title: 'Leave Applications',
          href: '/app/payroll/leaveApplications',
          icon: 'ic:round-transform'

          // icon: TransformOutlinedIcon
        },
        {
          title: 'Movement Register',
          href: '/app/payroll/movementRegister',
          icon: 'ic:baseline-transfer-within-a-station'

          // icon: TransferWithinAStationIcon
        },
        {
          title: 'Approvals',
          href: '/app/payroll/approvals',
          icon: 'ic:baseline-transform'
        }
      ]
    },
    {
      title: 'Academics',
      href: '/app/admin/classSetup',
      icon: `material-symbols-light:menu-book-outline`,
      items: [
        {
          title: 'Class Setup',
          href: '/app/admin/classSetup',
          icon: 'mingcute:settings-7-line'
        },
      ]
    },
  
    {
      title: 'Notifications',
      href: '/app/admin/notifications',
      icon: 'ion:notifications-outline'
    }
  ]
};


function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor:'#ffffff'
  },
  root1: {
    display: 'flex',
    paddingLeft: 256,
    transitionDuration: 500,
    backgroundColor:'#ffffff'

  },
  desktopDrawer: {
    width: 256,
    height: '100%',
    backgroundColor:Rules.nav_top_bar.navbar_background_color,
  
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const sections = [
  {
    items: [
      {
        icon: PieChartIcon,
        href: <Search />
      },
      {
        icon: BarChartIcon,
        href: <Search />
      }
    ]
  }
];

function renderRightNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceRightChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceRightChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderRightNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useRightStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const useLogoStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  desktopDrawer: {
    width: 70,
    top: 64,
    height: 'calc(100% - 64px)'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  menuButton: {
    marginRight: theme.spacing(2)
  },


  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 70
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const rightClasses = useRightStyles();
  const logoClasses = useLogoStyles();
  const location = useLocation();
  const { user } = useAuth();

  let users = '';
  const isMountedRef = useIsMountedRef();

  const [image, setImage] = useState(null);

  const [file, setFile] = React.useState([]);
  const arrayBufferToBase64 = buffer => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };
  const { open, setOpen } = useDashboardContext();

  let folderName = user['custom:institution_id']
  var bucketName = "erpinstitution"
  let img
  let logo
  const s3 = new AWS.S3();

  async function get_logo(image_path) {
    let img = await s3
        .getObject({
          Bucket: bucketName,
          Key: image_path
        })
        .promise()
        .then(res => {
          if (res) {
           
            return res;
            
          } else {
            return null;
          }
        })
        .catch(err => {
      
        });
        return img;
  }


  const getCustomers = useCallback(async () => {
    try {
      
      const get_image = gql`
      query get_setup {
        get_setup {
          _id
          institution_logo
        }
      }`;
      var image_path = encodeURIComponent(folderName) + "/" + encodeURIComponent("logo") + "/" +user['custom:institution_id']


      img = await get_logo(image_path)
          if (img === undefined || img === null) {
            logo = null 
          }else {
            var base64Flag = `data:${img.contentType};base64,`;
            var imgData = arrayBufferToBase64(img.Body);
            logo = base64Flag + imgData;
            setImage(logo);
          }
     
      
    } catch (err) {
    }
  }, [isMountedRef]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);
  
  
  const handleDrawer = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
 <PerfectScrollbar options={{ suppressScrollX: true }}>
 <Box pt={2.5} pb={1.4} px={1.5}>
          <Box mt={-1} ml={1.2} display="flex">
            {open === true && (
             <>
              <IconButton
                color="inherit"
                size="small"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                className={clsx(classes.menuButton, open)}
              >
                <MenuIcon fontSize="large" style={{color:'#ffffff'}} />
              </IconButton>
               <Box mr={0} />
               {open && <Box mt={1}>
                  <Typography
                    variant="h5"
                    style={{ color: '#ffffff', fontSize: '20px' }}
                  >
                    Admin Console
                  </Typography>
                </Box>

                }
                
</>
            )}
            {open === false && (
              <>
              <Box mr={1}/>
              <IconButton
                color="inherit"
                size="small"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                className={clsx(classes.menuButton1, open)}
              >
                <MenuOpenIcon fontSize="large" style={{color:'black'}}/>
              </IconButton></>
            )}
           
          </Box>
        </Box>{' '}
        <Divider style={{ backgroundColor: Rules.nav_top_bar.navbar_items_hover_color }} />

        <Box p={2}>
            <List
              key={Users.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {Users.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: Users.items,
                pathname: location.pathname
              })}
            </List>
       
        </Box>
        <Divider />
        </PerfectScrollbar>

        <Box flexGrow={1} />
       
        <Divider />
        <Box p={2} flexDirection="column" justifyContent="flex-end" >
          {/* <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
             
  
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              // component={RouterLink}
              // to="/docs"
              className={classes.action}
            >
              Check our docs
            </Link>
            </Box> */}
       
        </Box>

    </Box>
  );

  const Rightcontent = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {sections.map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderRightNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  const Logocontent = (
    <Box height="100%" display="flex" flexDirection="column" >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
       
        <Divider />
     
        <Box p={2}>
        </Box>
        <Divider />
        <Box p={2} flexDirection="column" justifyContent="flex-end">
          <Box flexGrow={1} />
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              // component={RouterLink}
              // to="/docs"
              className={classes.action}
            >
              Check our docs
            </Link>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={open ? classes.root1 : classes.root} {...rest}>
        <Toolbar className={classes.toolbar}>
        
         {open === false && (
            <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            className={clsx(classes.menuButton, open)}
            >
            <MenuOpenIcon fontSize = "large" style={{color:'black'}} />
            </IconButton>
          )}
                   

         
          <Box ml={2} flexGrow={1} />
          <Year />
              <Term />
           <Notifications />
          <Settings /> 
          <Box ml={2}>
            <Account />
          </Box>
        </Toolbar>
      </AppBar>
      {open && (
        <Drawer
          classes={{ paper: classes.desktopDrawer }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          {content}
        </Drawer>
      ) }
     {/* {open === false && (
        <Hidden mdDown>
       <Drawer
       classes={{ paper: logoClasses.desktopDrawer }}
       variant="persistent"
       anchor="left"
       open={true}
     >
       {Logocontent}
     </Drawer>
     </Hidden>
     )} */}
      {/* <Drawer
        classes={{ paper: rightClasses.desktopDrawer }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        {Rightcontent}
      </Drawer> */}
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
