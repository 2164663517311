import { makeStyles } from '@material-ui/core';
import Rules from 'src/Rules.json';

const stylesUseStyles = makeStyles((theme) => ({
  loginRoot: {
    display: 'flex',
    minHeight: '100vh',
    backgroundImage: Rules.login_page.backgroundImage, 
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    backgroundRepeat: 'no-repeat',
    boxSizing: "border-box",
    alignItems: 'center', 
    justifyContent: 'center', 
  },
  signUpRoot: {
    display: 'flex',
    minHeight: '100vh',
    backgroundImage: Rules.signup_page.backgroundImage, 
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    backgroundRepeat: 'no-repeat',
    boxSizing: "border-box",
    alignItems: 'center', 
    justifyContent: 'center', 
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default stylesUseStyles;
