import React, { useState }  from 'react';
import { makeStyles ,Grid , Box , Container,Avatar, Divider, Typography} from '@material-ui/core';
import Page from 'src/components/Page';
import useAuth from 'src/hooks/useAuth';
import { useHistory  } from 'react-router-dom';
import { Icon } from "@iconify/react";
import getName from 'src/utils/getName';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
  }
}));

const HomeView = () => {
  const classes = useStyles();
  const { user , user_group_select} = useAuth();
  const history = useHistory()
  const fullScreenHeight = window.innerHeight
  const isMountedRef = useIsMountedRef();
  const [error, setError] = useState(false);

  const handleRoute = (e , group) => {
    try {
    user_group_select(group)
    if (isMountedRef.current) {
      history.push("/app/admin/home")
    }
  } catch (err) {
    if (err.response) {
      if (isMountedRef.current) {
        setError(err.response.data.message);
      }
    } else {
      if (isMountedRef.current) {
        setError('Network Error');
      }
    }
  }
  }

  return (
  <Page className={classes.root} title="Home">
    <Container maxwidth = {false}>
      <Box 
       justifyContent="center"
       align="center"
       alignItems="center"
       display="flex"
       flexDirection="column"
       
      height={`${fullScreenHeight}px`}>
        <Box style={{ height : '600px', width : '600px' , backgroundColor : '#FFFFFF'}}>
          <Box padding={2}>
          <Typography variant='h4' style={{textAlign: 'start'}}> Select a Group </Typography>
          <Typography variant='body2' style={{textAlign: 'start'}} > Choose from the list of your existing groups </Typography>
          </Box>
        <Divider />
        <Box padding={2} >
        <Grid container spacing = {3} >
        {
          user.user_groups.map((user) => 
          <Grid item md = {6}
                onClick = {(e) => handleRoute(e , user.user_group) }
          >
          <Box
              sx={{
                    backgroundColor : '#eeeeee',
                    borderRadius: "16px",
                    display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        alignContent: "center",
        cursor: 'pointer',
      }}
    >

      <Box display={"flex"} alignItems={'center'} m = {2}>
        <Avatar
          style={{
            height: "50px",
            width: "50px",
            backgroundColor: "#002250",
          }}
        >
          <Icon icon={user.icon} style={{ fontSize: "34px" }} />
        </Avatar>
        <Box mr={2} />
        <Box display='flex' flexDirection='column'>
        <Typography variant="h5" style={{textAlign: 'start' , color : "#002250"}} >
        {getName(user.user_group)}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: "#A4A6A4",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
          }}
          style={{textAlign: 'start'}}
        >
          {user.description}
        </Typography>
        </Box>

      </Box>
    
      {/* <Box mb={2} mx = {2}>
       
      </Box> */}

    </Box>
   

  </Grid>
  )
}
        </Grid>
        </Box>
        </Box>
        </Box>
    </Container>
  </Page>
  );
};

export default HomeView;
