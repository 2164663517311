import { withStyles} from '@material-ui/core';
import {Box} from  '@material-ui/core';

const RoundedBox = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.white,
    borderRadius: (props) => props.borderRadius ||'8px',
    elevation:12,
    borderWidth:0,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.02)',
  }
}))(Box);


export default RoundedBox;
