import React, { createContext, useReducer } from 'react';
import gql from 'graphql-tag';
import {client} from '../utils/GQLClient';
import getregistrationquery from '../views/Setup/query'
import moment from 'moment';

const initialCreateState = {
  isCreated: false,
  isInitialised: false,
  user: null,
  users: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isCreated, user } = action.payload;

      return {
        ...state,
        isCreated,
        isInitialised: true,
        user
      };
    }

    case 'UPDATE_KYC': {
      const { user ,updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        user
      };
    }
    case 'UPDATE_KYC_ERR': {
      const { err } = action.payload;

      return {
        ...state,
        updatesuccessfull: false,
        err
      };
    }

    case 'ADD_ACCREDITATION': {
      const { user ,updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        user
      };
    }
    case 'ADD_ACCREDITATION_ERR': {
      const { err } = action.payload;

      return {
        ...state,
        updatesuccessfull: false,
        err
      };
    }

    default: {
      return { ...state };
    }
  }
};

// const getinstitutionquery = gql`
// query get_institution {
//   get_institution {
//     _id
//       user_id
//       institution_name
//       first_name
//       last_name 
//       owner_email
//       owner_contact
//       address {
//         street
//           city
//           state
//           postal_code
//           country
//       }
//       contact{
//         phone
//           mobile 
//           fax
//           website
//           secondary_email
//       }
//   }
// }
// `;

const SetupContext = createContext({
  ...initialCreateState,
  update_kyc: () => Promise.resolve(),
  add_accrediation : () => Promise.resolve(),
  edit_accrediation : () => Promise.resolve(),
  delete_accrediation : () => Promise.resolve(),
  add_department : () => Promise.resolve(),
  edit_department : () => Promise.resolve(),
  delete_department : () => Promise.resolve()
});

export const SetupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCreateState);

  const update_kyc = async (        
    pan ,             
registration_no  ,
aicte_no ,        
college_code  ,      ) => {
  

      const updatekyc = {
        pan:     pan,          
        registration_no:   registration_no, 
        aicte_no:  aicte_no,        
        college_code :     college_code
      }


    try {
      const ADD = gql`
        mutation update_kyc(
          $kyc                   : kyc
      
        ) {
          update_kyc(
              kyc                : $kyc      
         
          ) {
          _id
          kyc {
            pan
            registration_no
            college_code
            aicte_no
          }
          }
        }
       
      `;
      const { data } = await client('adminapi')
        .mutate({
          mutation: ADD,
          
          variables: {
            kyc                :updatekyc        ,
          },
          
          refetchQueries:() => [{query:getregistrationquery}]
           
       
     
        })
        .then(res => {
   
          return res;
        });



      dispatch({
        type: 'UPDATE_KYC',
        payload: {
          user: data.update_kyc.kyc,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      dispatch({
        type: 'UPDATE_KYC_ERR',
        payload: {
          err: err
        }
      });

    }
  };

 


 
  const add_accrediation = async (        
    accrediation_name,
    accrediation_id  ,
    start_date   ,    
    end_date  ,         ) => {
  

      const addaccreditation = {
        accrediation_name   : accrediation_name,
        accrediation_id  : accrediation_id,
        start_date     : start_date,
        end_date       : end_date,
        
        
        
      }

    try {
      const ADD = gql`
        mutation add_accrediation(
          $accrediations                   : accrediations
        ) {
          add_accrediation(
            accrediations                : $accrediations      
          ) {
          accrediations {
            accrediation_name
            accrediation_id  
            start_date     
            end_date       
          }
          }
        }
       
      `;
      const { data } = await client('adminapi')
        .mutate({
          mutation: ADD,    
          variables: {
            accrediations                :addaccreditation        ,
          },
        })
        .then(res => {
    
          return res;
        });



      dispatch({
        type: 'UPDATE_KYC',
        payload: {
          user: data,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      dispatch({
        type: 'UPDATE_KYC_ERR',
        payload: {
          err: err
        }
      });

    }
  };


  const edit_accrediation = async (
    id,        
    accrediation_name,
    accrediation_id  ,
    start_date   ,    
    end_date  ,         ) => {
  

      const editaccrediation = {
        accrediation_name   : accrediation_name,
        accrediation_id  : accrediation_id,
        start_date     : start_date,
        end_date       : end_date,
        
        
        
      }

    try {
      const ADD = gql`
        mutation edit_accrediation(
          $_id: String!
          $accrediations                   : accrediations
        ) {
          edit_accrediation(
            _id                : $_id
            accrediations                : $accrediations      
          ) {
          accrediations {

            accrediation_name
            accrediation_id  
            start_date     
            end_date       
          }
          }
        }
       
      `;
      const { data } = await client('adminapi')
        .mutate({
          mutation: ADD,    
          variables: {
            _id:        id,
            accrediations                :editaccrediation        ,
          },
        })
        .then(res => {

          return res;
        });


      dispatch({
        type: 'UPDATE_KYC',
        payload: {
          user: data,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      dispatch({
        type: 'UPDATE_KYC_ERR',
        payload: {
          err: err
        }
      });
  
    }
  };


  const delete_accrediation = async (
    id,        
         ) => {



    try {
      const ADD = gql`
        mutation delete_accrediation(
          $_id: String!

        ) {
          delete_accrediation(
            _id                : $_id
      
          ) {
          accrediations {

            accrediation_name
            accrediation_id  
            start_date     
            end_date       
          }
          }
        }
       
      `;
      const { data } = await client('adminapi')
        .mutate({
          mutation: ADD,    
          variables: {
            _id:        id,

          },
        })
        .then(res => {
     
          return res;
        });

     

      dispatch({
        type: 'UPDATE_KYC',
        payload: {
          user: data,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      dispatch({
        type: 'UPDATE_KYC_ERR',
        payload: {
          err: err
        }
      });
    
    }
  };


  const add_department = async (        
    dept_name,
    dept_short_name  ,
      ) => {
  

      const adddepartment = {
        dept_name   : dept_name,
        dept_short_name  : dept_short_name,
        
      }


    try {
      const ADD = gql`
        mutation add_department(
          $departments                   : departments
        ) {
          add_department(
            departments                : $departments      
          ) {
            departments {
              dept_name   
              dept_short_name     
          }
          }
        }
       
      `;
      const { data } = await client('adminapi')
        .mutate({
          mutation: ADD,    
          variables: {
            departments                :adddepartment        ,
          },
        })
        .then(res => {
       
          return res;
        });

 

      dispatch({
        type: 'UPDATE_KYC',
        payload: {
          user: data,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      dispatch({
        type: 'UPDATE_KYC_ERR',
        payload: {
          err: err
        }
      });

    }
  };

  const edit_department = async (    
    id,      
    dept_name,
    dept_short_name  ,
      ) => {
  

      const editdepartment = {
        dept_name   : dept_name,
        dept_short_name  : dept_short_name,
        
      }


    try {
      const ADD = gql`
        mutation edit_department(
          $_id: String!
          $departments                   : departments
        ) {
          edit_department(
            _id                : $_id
            departments                : $departments      
          ) {
            departments {
              dept_name   
              dept_short_name     
          }
          }
        }
       
      `;
      const { data } = await client('adminapi')
        .mutate({
          mutation: ADD,    
          variables: {
            _id:        id,
            departments                :editdepartment        ,
          },
        })
        .then(res => {
   
          return res;
        });



      dispatch({
        type: 'UPDATE_KYC',
        payload: {
          user: data,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      dispatch({
        type: 'UPDATE_KYC_ERR',
        payload: {
          err: err
        }
      });

    }
  };

  const delete_department = async (
    id,        
         ) => {

    try {
      const ADD = gql`
        mutation delete_department(
          $_id: String!

        ) {
          delete_department(
            _id                : $_id
      
          ) {
            departments {
              dept_name   
              dept_short_name     
          }
          }
        }
       
      `;
      const { data } = await client('adminapi')
        .mutate({
          mutation: ADD,    
          variables: {
            _id:        id,

          },
        })
        .then(res => {
       
          return res;
        });



      dispatch({
        type: 'UPDATE_KYC',
        payload: {
          user: data,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      dispatch({
        type: 'UPDATE_KYC_ERR',
        payload: {
          err: err
        }
      });
    
    }
  };


  return (
    <SetupContext.Provider
      value={{
        ...state,
        update_kyc,
        add_accrediation,
        edit_accrediation,
        delete_accrediation,
        add_department,
        edit_department,
        delete_department
      }}
    >
      {children}
    </SetupContext.Provider>
  );
};

export default SetupContext;